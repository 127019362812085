import AccountCircle from "@mui/icons-material/AccountCircle";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import ChairIcon from "@mui/icons-material/Chair";
// import CreditCardIcon from "@mui/icons-material/CreditCard";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Home from "@mui/icons-material/Home";
import SellIcon from "@mui/icons-material/Sell";

export interface RouteType {
  description?: string
  icon: any
  imageUrl?: string
  name: string
  newTab?: boolean // open in new tab
  route: string
}

export interface FurnitureRoutes {
  [key: string]: RouteType
}

export const routes: FurnitureRoutes = {
  aiGenerate: {
    description: "create new furniture",
    icon: EditIcon,
    imageUrl: "https://images.thefurnitureapp.com/app/cards/design.webp",
    name: "design furniture",
    route: "/ai/create",
  },
  aiImages: {
    description: "my furniture creations",
    icon: ChairIcon,
    imageUrl: "https://images.thefurnitureapp.com/app/cards/myfurniture.webp",
    name: "my furniture",
    route: "/ai/images",
  },
  aiImagesFav: {
    description: "my best furniture",
    icon: FavoriteIcon,
    imageUrl: "https://images.thefurnitureapp.com/app/cards/favorite.webp",
    name: "favorite furniture",
    route: "/ai/images/favorites",
  },
  /*
  billing: {
    icon: CreditCardIcon,
    imageUrl: "https://images.thefurnitureapp.com/app/cards/subscriptions.webp",
    name: "subscription",
    route: "/billing",
  },
  */
  blueprintList: {
    icon: DesignServicesIcon,
    imageUrl: "https://images.thefurnitureapp.com/app/cards/blueprints.webp",
    name: "blueprints",
    route: "/blueprint/list",
  },
  blueprintOrder: {
    icon: SellIcon,
    name: "order blueprint",
    route: "/orderBlueprint",
  },
  blueprint: {
    icon: ArchitectureIcon,
    name: "view blueprint",
    route: "/blueprint",
  },
  home: {
    icon: Home,
    name: "home",
    route: "/",
  },
  profile: {
    icon: AccountCircle,
    name: "profile",
    route: "/profile",
  },
  bookDemo: {
    icon: null,
    name: "book a demo",
    route: "/bookdemo",
  },
};
